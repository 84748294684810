import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Working with Alarmserver Headers",
  "path": "/Frequently_Asked_Question/WQHD_Alarmserver_Custom_Headers/",
  "dateChanged": "2024-02-21",
  "author": "Mike Polinowski",
  "excerpt": "All 2K+ WQHD models now allow you to set custom headers for Alarmserver Request.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Working with Alarmserver Headers' dateChanged='2024-02-21' author='Mike Polinowski' tag='INSTAR IP Camera' description='All 2K+ WQHD models now allow you to set custom headers for Alarmserver Request.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_2fa_Authentication/' locationFR='/fr/Frequently_Asked_Question/WQHD_2fa_Authentication/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "working-with-alarmserver-headers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#working-with-alarmserver-headers",
        "aria-label": "working with alarmserver headers permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Working with Alarmserver Headers`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I use the alarm server of my 9408 2K+ to send an HTTPS request to my local ntfy server (see ntfy.sh). This in turn sends the message to all mobile devices that have subscribed to the topic. The request works fine so far.`}</p>
    <p>{`Unfortunately, it is currently only possible to edit the parameters in the request (body) itself. However, ntfy uses information in the header to transfer certain parameters (e.g. the title, a priority, etc.). Is there a way to edit the header data of the requests in order to transfer individual parameters?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Our 2K+ WQHD models now allow you to set custom headers for `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Alarm_Server/"
      }}>{`Alarmserver Request`}</a>{`. Those can be configured directly through the webUI or dynamically set through the `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/Smarthome_Menu/Alarm_Server/"
      }}>{`HTTP (CGI) Interface`}</a>{` or the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`MQTT Interface`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`See also `}<a parentName="p" {...{
          "href": "/en/Frequently_Asked_Question/Debugging_Alarmserver_Headers/"
        }}>{`Debugging the HTTP Alarmserver in Node-RED`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "basic-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic-setup",
        "aria-label": "basic setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic Setup`}</h2>
    <p>{`Open your cameras WebUI and navigate to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Alarm_Server/"
      }}>{`Smarthome/Alarmserver Menu`}</a>{`. Click on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`+`}</code>{` icon next to `}<strong parentName="p">{`Request Header`}</strong>{` to add `}<strong parentName="p">{`up to 5`}</strong>{` custom headers:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "594px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1808b1ff29399123b96550d68468dd1b/5fd3e/WQHD_Alarmserver_Custom_Headers_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAA7rAAAO6wFxzYGVAAACYklEQVQ4y5VTy27TUBT0Z5GnHTuhQrJ9H75+PyOKIJV4dJ/uEeoCseo3ID6ii3bBR7BgB18xcG5t10lNJBajE8fnjufcmWMESqGqSpRlgaqqUBQFpJQQQvT1FKgnTjKIKwnRCBhSBkiSBHEc9/WYkOoplDwAu/TASg4jCAI0Ta1VNnUNpdQTslMKuRT4dMaQX3F4bzgplHrcPM81aRiGB6rot+M4sG0b8/m8h+042Gw2D+/a957nPRAmSayRpumBwo7QsiwsFgtMJhNMp9Mey+USpmXCNE397LoujEBK1HWFsiyx3TaIomhUIakhgtlspslJJRGRMss09XOvkIwgpGkyqpAOmu2hTh0RE4bX0BOSurIotNIoenqHq9VKY0hEyki5Y9tYr9dYWdYjYZ5lyLIURZGPKqSDREijdugJHUcTkjGe53emJH0GsywDRemYdDQyBM7BhYR4tYfMXsKQR8Elhf+zKRrUp2IIGbTBrmtst1u9emNbQj2jUKqtIdTrPWS67VYv7puGY1KlkRxnre+KYjMKSoFzBsbFY2wof0RIBMPFZ4zrYBOGoR5CB37yDL7fmkIbQmbEcYQwVAem0AfIYdt2+sj8Cz1hGEUakQ74YbiJkGJBm2K1Ae+qXjvL6itjrHV5gOG4x6YobYLSZqgO9H8L6jWKiOPnFwu/bp7j980GPz6vcJ5xeFwikEJHQTAXovkA+fEe+fUdouvv4Ptv+Gqucb96gduNi9v5Gm9dH4YKBN7XPi4bpvGu8hEGpJLc7pRyyDCBLC+gqh2C6gIiO8e5z7DzOXZcYOcxxH9d/gOK497MslUtiAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1808b1ff29399123b96550d68468dd1b/e4706/WQHD_Alarmserver_Custom_Headers_01.avif 230w", "/en/static/1808b1ff29399123b96550d68468dd1b/d1af7/WQHD_Alarmserver_Custom_Headers_01.avif 460w", "/en/static/1808b1ff29399123b96550d68468dd1b/2ce6e/WQHD_Alarmserver_Custom_Headers_01.avif 594w"],
              "sizes": "(max-width: 594px) 100vw, 594px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1808b1ff29399123b96550d68468dd1b/a0b58/WQHD_Alarmserver_Custom_Headers_01.webp 230w", "/en/static/1808b1ff29399123b96550d68468dd1b/bc10c/WQHD_Alarmserver_Custom_Headers_01.webp 460w", "/en/static/1808b1ff29399123b96550d68468dd1b/bc223/WQHD_Alarmserver_Custom_Headers_01.webp 594w"],
              "sizes": "(max-width: 594px) 100vw, 594px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1808b1ff29399123b96550d68468dd1b/81c8e/WQHD_Alarmserver_Custom_Headers_01.png 230w", "/en/static/1808b1ff29399123b96550d68468dd1b/08a84/WQHD_Alarmserver_Custom_Headers_01.png 460w", "/en/static/1808b1ff29399123b96550d68468dd1b/5fd3e/WQHD_Alarmserver_Custom_Headers_01.png 594w"],
              "sizes": "(max-width: 594px) 100vw, 594px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1808b1ff29399123b96550d68468dd1b/5fd3e/WQHD_Alarmserver_Custom_Headers_01.png",
              "alt": "Working with Alarmserver Headers",
              "title": "Working with Alarmserver Headers",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now add your custom headers as per `}<a parentName="p" {...{
        "href": "https://docs.ntfy.sh/publish/?h=header#using-a-header"
      }}>{`ntfy documentation`}</a>{` - e.g.:`}</p>
    <ul>
      <li parentName="ul">{`Setting the message title: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`X-Title: Notification from my camera`}</code></li>
      <li parentName="ul">{`Adding tags: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`X-Tags: warning,skull`}</code></li>
      <li parentName="ul">{`Setting the message priority: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`X-Priority: 5`}</code></li>
      <li parentName="ul">{`Adding a link that opens your cameras webUI: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`X-Click: http://192.168.2.125/`}</code></li>
      <li parentName="ul">{`Attaching a link to your cameras snapshot: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`X-Attach: http://192.168.2.125/snap.cgi?user=admin&pwd=instar`}</code></li>
      <li parentName="ul">{`Using a snapshot from your camera as message Icon: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`X-Icon: http://192.168.2.125/snap.cgi?user=admin&pwd=instar`}</code></li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: That the examples above use the local IP address of your camera. This will only be accessible if the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`ntfy`}</code>{` server and your smartphone - that you are using with the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`ntfy`}</code>{` app - are on the same local network as your camera. Or you are using a VPN to connect to your local network. Otherwise you will have to swap the local IP with your `}<a parentName="p" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Network/Remote_Access/"
        }}>{`DDNS Address`}</a>{` and `}<a parentName="p" {...{
          "href": "/en/Internet_Access/Port_Forwarding/"
        }}>{`forward your camera's web port`}</a>{` to be able to access the address from the internet.`}</p>
    </blockquote>
    <h2 {...{
      "id": "dynamic-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dynamic-configuration",
        "aria-label": "dynamic configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dynamic Configuration`}</h2>
    <p>{`In case you are use a smarthome system you might want to adjust those headers dynamically using CGI or MQTT commands. An example would be - your smarthome knows that nobody is at home right now, thus you want the priority of a message notifying you about a detected motion to increase.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "/en/1440p_Series_CGI_List/Smarthome_Menu/Alarm_Server/"
        }}>{`HTTP (CGI) Interface`}</a></p>
    </blockquote>
    <p>{`To increase the priority simply send the following command to your camera:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.168/param.cgi?cmd=setasattr&as_index=1&as_header1=1&as_headerattr1=X-Priority&as_headerval1=5`}</code></li>
    </ul>
    <p><em parentName="p">{`Please click on the link above to see the entire API documentation.`}</em></p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
        }}>{`MQTT Interface`}</a></p>
    </blockquote>
    <p>{`The same can be accomplish through the MQTT interface using the following commands:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`smarthome/alarmserver/header1`}</code>{`: Payload: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val": "1"}`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`smarthome/alarmserver/headerkey1`}</code>{`: Payload: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val": "X-Priority"}`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`smarthome/alarmserver/headerval1`}</code>{`: Payload: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val": "5"}`}</code></li>
    </ul>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      